<template>
    <div>
        <a-select v-model="researchGroupId" placeholder="请选择课题组"
            style="width: 210px;margin-left: 12px;" dropdownClassName="research-group-dropdown"
            @dropdownVisibleChange="handleResearchGroupDrownVisibleChange" @search="handleSearchResearchGroupList"
            :showSearch="true" :filter-option="false" :allowClear="true" @change="handleSelect">
            <a-select-option v-for="(group, groupIndex) in researchGroupList" :key="group.id">
                {{ group.name }}
            </a-select-option>
            <a-select-option :disabled="true" key="loading">
                <div v-if="isMaxPage && !isResearchGroupListLoading && researchGroupList.length == 0">
                    <a-empty size="small" style="width: 100%;"></a-empty>
                </div>
                <div v-else-if="isMaxPage">
                    已加载全部
                </div>
                <div v-else-if="isResearchGroupListLoading">
                    <a-icon slot="indicator" type="loading" style="font-size: 14px" spin />
                    <span style="margin-left: 8px;">正在加载</span>
                </div>
                <div v-else  style="color: #fff;">
                    暂无数据
                </div>
            </a-select-option>
        </a-select>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { computed, ref, nextTick, onMounted } from 'vue-demi';
import { getResearchGroup } from '@/api/researchGroup.js'
import { useInfiniteScroll } from '@vueuse/core';
export default {
   /*  props: {
        researchGroupList: {
            default: () => ([])
        }
    }, */
    setup(props, context) {
        onMounted(() => {
            
        })
        // 选中的课题组Id
        const researchGroupId = ref(undefined);

        // 课题组列表
        const researchGroupList = ref([]);

        // 课题组总数
        const researchGroupCount = ref(0);

        // 课题组总页数
        const researchGroupTotalPage = ref(2);

        // 课题组页数
        const researchGroupPageNo = ref(1);

        // 课题组单页数量
        const researchGroupPageSize = ref(15);
        // 是否正在加载课题组列表
        const isResearchGroupListLoading = ref(false);

        const researchGroupNameSearchVal = ref('')

        const filterOption = (input, option) => {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        }

        const isMaxPage = computed(() => {
            return researchGroupPageNo.value > researchGroupTotalPage.value;
            /* const maxPage = res
            return Math.ceil( ) */
        })

         // 获取课题组列表
         const getResearchGroupList = async () => {
            if(isMaxPage.value) {
                isResearchGroupListLoading.value = false;
                return ;
            }
            isResearchGroupListLoading.value = true;
             const res = await getResearchGroup({
                 pageNo: researchGroupPageNo.value,
                 pageSize: researchGroupPageSize.value,
                 searchBeginTime: undefined,
                 searchBeginTime: undefined,
                 status: '',
                 name: researchGroupNameSearchVal.value
             });
             if(res.success) {
                 researchGroupTotalPage.value = res.data.totalPage;
                 researchGroupList.value.splice(researchGroupList.value.length, 0, ...res.data.rows);
                 researchGroupPageNo.value = researchGroupPageNo.value + 1;
                 //researchGroupList.value = res.data.rows;
             }
             else {
                 
             }
          
            isResearchGroupListLoading.value = false;
        }

        // 搜索防抖
        const searchDobounce = debounce(getResearchGroupList,800);

        // 课题组输入框搜索
        const handleSearchResearchGroupList = (val) => {
            researchGroupList.value = [];
            researchGroupNameSearchVal.value = val;
            //researchGroupCount.value = 0;
            researchGroupTotalPage.value = 2;
            researchGroupPageNo.value = 1;
            isResearchGroupListLoading.value = true
            searchDobounce();
        }

        // 课题组下拉可见事件
        const handleResearchGroupDrownVisibleChange = async (val) => {
            if (val) {
                if(researchGroupPageNo.value == 1 && !isResearchGroupListLoading.value) {
                    await getResearchGroupList();
                }
                setTimeout(() => {
                    nextTick(() => {
                        useInfiniteScroll(ref(document.querySelector('.research-group-dropdown > .ant-select-dropdown-content > .ant-select-dropdown-menu')), () => {
                          /*  researchGroupPageNo.value = researchGroupPageNo.value + 1; */
                            console.log('trick')
                            isResearchGroupListLoading.value = true;
                            //researchGroupPageNo.value = researchGroupPageNo.value + 1;
                            /* getResearchGroupList(); */
                            searchDobounce()
                        })
                    })
                }, 0);

            }
        }

        const handleSelect = (value) => {
            console.log(value)
            context.emit('onSelect', value);
            researchGroupId.value = value;
        }

        


        return {
            researchGroupList,
            handleSearchResearchGroupList,
            handleResearchGroupDrownVisibleChange,
            isMaxPage,
            filterOption,
            isResearchGroupListLoading,
            handleSelect,
            researchGroupId
        }
    }
}
</script>

<style lang="less" scoped>
.research-group-dropdown > .ant-select-dropdown-content > .ant-select-dropdown-menu {
    max-height: 300px;
    overflow: auto;
}
</style>