<template>
	<div>
		<div class="search">
			<searchBox @select="select" @submit="submit"></searchBox>
		</div>
		<div class="screen" v-if="model == '实验'">
			<div style="display: flex;color: black;padding-left: 50px;">
				<div style="margin-right: 30px;">归属项目：</div>
				<div>
					<a-spin :spinning="isProjectListLoading">
						<a-select style="width: 320px;" v-model="projectId" showSearch placeholder="请选择" :allowClear="true"
							:filter-option="filterOption">
							<a-select-option v-for="(option, optionIndex) in projectList" :value="option.projectId">
								{{ option.projectName }} ( {{ option.code }} )
							</a-select-option>
						</a-select>
					</a-spin>
				</div>
			</div>
			<div style="display: flex;color: black;padding-left: 50px;">
				<div style="margin-right: 30px;">匹配范围：</div>
				<div @click="selectRange(item.key)"
					:class="slectRange == item.key ? 'screen_list listActive' : 'screen_list'"
					v-for="(item, index) in rangeColumn" :key="item.key">
					<div>{{ item.name }}</div>
				</div>
			</div>
			<div style="display: flex;color: black;padding-left: 50px;">
				<div style="margin-right: 30px;">实验状态：</div>
				<div @click="selectStatus(item.key)"
					:class="slectStatus == item.key ? 'screen_list listActive' : 'screen_list'"
					v-for="(item, index) in statusColumn" :key="item.key">
					<div>{{ item.name }}</div>
				</div>
			</div>
			<div style="display: flex;color: black;align-items: center;padding-left: 50px;">
				<div style="margin-right: 30px;">创建时间：</div>
				<a-date-picker :allowClear="true" :disabledDate="disabledDate" v-model="startTime" />
				<div style="width: 14px;height: 1px;background-color: rgba(0, 0, 0, 0.45);margin: 0 10px;"></div>
				<a-date-picker :allowClear="true" :disabledDate="disabledDate2" v-model="endTime" />
			</div>
			<div style="display: flex;color: black;align-items: center;margin-left: -28px;padding-left: 50px;">
				<div style="margin-right: 30px;">最近更新时间：</div>
				<a-date-picker :allowClear="true" :disabledDate="disabledDateUp" v-model="upStartTime" />
				<div style="width: 14px;height: 1px;background-color: rgba(0, 0, 0, 0.45);margin: 0 10px;"></div>
				<a-date-picker :allowClear="true" :disabledDate="disabledDateUp2" v-model="upEndTime" />
			</div>
			<div style="display: flex;color: black;align-items: center;margin-left: 13px;padding-left: 50px;">
				<div style="margin-right: 30px;">创建人：</div>
				<a-input v-model="createName" placeholder="请输入创建人" style="width: 185px;height: 30px;" allowClear>
				</a-input>
			</div>
		</div>
		<div class="screen h" v-if="model == '项目'">
			<div style="display: flex;color: black;padding-left: 50px;">
				<div style="margin-right: 30px;">课题组：</div>
				<div>
					<SearchResearchGroupSelect @onSelect="handleReseachGroupSelect"></SearchResearchGroupSelect>
				</div>

			</div>
			<div style="display: flex;color: black;padding-left: 50px;">
				<div style="margin-right: 30px;">匹配范围：</div>
				<div @click="selectItemRange(item.key)"
					:class="selectItemKey == item.key ? 'screen_list listActive' : 'screen_list'"
					v-for="(item, index) in itemRangeColumn" :key="item.key">
					<div>{{ item.name }}</div>
				</div>
			</div>

			<div style="display: flex;color: black;align-items: center;padding-left: 50px;" v-if="model == '实验'">
				<div style="margin-right: 30px;">创建时间：</div>
				<a-date-picker :allowClear="true" :disabledDate="disabledDate" v-model="startTime" />
				<div style="width: 14px;height: 1px;background-color: rgba(0, 0, 0, 0.45);margin: 0 10px;"></div>
				<a-date-picker :allowClear="true" :disabledDate="disabledDate2" v-model="endTime" />
			</div>
			<div style="display: flex;color: black;align-items: center;padding-left: 50px;" v-if="model == '项目'">
				<div style="margin-right: 30px;">创建时间：</div>
				<a-date-picker :allowClear="true" v-model="createTime" />
			</div>

			<div style="display: flex;color: black;align-items: center;margin-left: 13px;padding-left: 25px;">
				<div style="margin-right: 30px;">项目负责人：</div>
				<a-input v-model="owner" placeholder="请输入项目负责人" style="width: 185px;height: 30px;" allowClear></a-input>
			</div>
		</div>
		<a-spin :spinning="loading" tip="正在加载中...">
			<div class="content" v-if="model == '实验'" ref="experimentRef">
				<div class="msg" v-for="(item, index) in experData">
					<div class="msg-content">
						<div
							style="font-size: 18px;color: rgba(0, 0, 0, 0.85);font-weight: 550; margin-right: 16px; min-width: 23px;">
							{{
								index + 1
							}}</div>
						<!-- <img src="../../assets/not.jpg" style="width: 52px;margin: 0 10px;"> -->
						<div
							style="font-size: 14px;min-height: 55px;display: flex;flex-direction: column;align-items: flex-start;">
							<div> <a style="min-width: 23px;color: rgba(0, 0, 0, 0.85);"
									:href="handleExperimentItemClick(item)" target="_blank" class="link"> <span
										v-html="item.title"></span> （<span v-html="item.code"></span>） </a></div>
							<div style="color: rgba(0,0,0,0.65); margin-top: 8px;">
								<span style="color: rgba(0,0,0,0.65);"> 创建人: </span>
								<span style="color: rgba(0,0,0,0.65);">{{ item.createName }}</span>
								<span v-if="item.keyWord != ''" style="margin-left: 50px;">关键词：</span>
								<span v-html="item.keyWord"></span>
							</div>
							<!-- <div style="color: rgba(0,0,0,0.65); margin-top: 8px;">
								<div style="display: flex;">
									<span> 归属项目： </span>
									<span> {{ item.projectName }} </span>
								</div>
							</div> -->
						</div>
						<div style="flex: 1;"></div>
						<div style="margin-left: 10px;">
							<div style="display: flex;align-self: flex-end;color: rgba(0,0,0,0.65);">
								<span style="width: 120px; text-align: right;">最近更新时间：</span>
								<span style="min-width: 180px;text-align: left;">{{ item.updateTime }}</span>
							</div>
							<div style="display: flex;align-self: flex-end;color: rgba(0,0,0,0.65); margin-top: 8px;">
								<span style="width: 120px; text-align: right;">创建时间：</span>
								<span>{{ item.createTime }}</span>
							</div>
						</div>

					</div>
				</div>
				<div v-if="experData.length === 0"
					style="display: flex; align-items: center; justify-content: center;height: 100%;">
					<a-empty></a-empty>
				</div>
			</div>
			<div class="content" v-if="model == '项目'" ref="projectRef">
				<div class="msg" v-for="(item, index) in itemData" @click="handleItemClick(item)">
					<div class="msg-content">
						<div style="font-size: 18px;color: rgba(0, 0, 0, 0.85);font-weight: 550; min-width: 23px;">{{ index
							+ 1 }}</div>
						<!-- <img src="../../assets/not.jpg" style="width: 52px;margin: 0 10px;"> -->
						<div style="background: #FFBB00; padding: 5px; margin-left: 16px; margin-right: 16px;">
							<svg-icon name="group" style="font-size: 40px; fill:#ffffff; display: block;"> </svg-icon>
						</div>
						<div
							style="font-size: 14px;min-height: 55px;display: flex;flex-direction: column;align-items: flex-start; flex">
							<div> <a style="min-width: 23px;color: rgba(0, 0, 0, 0.85);" :href="handleItemClick(item)"
									target="_blank" class="link"> <span v-html="item.name"> </span> （<span
										v-html="item.code"></span>） </a></div>
							<div style="color: rgba(0,0,0,0.65); margin-top: 8px;">
								<span style="color: rgba(0,0,0,0.65);"> 负责人: </span>
								<span>{{ item.headName }}</span>
								<span style="margin-left: 50px;">创建时间：</span>
								<span>{{ item.createdTime }}</span>
							</div>
							<div style="margin-top: 8px; display: flex;color: rgba(0,0,0,0.65);">
								<span style="white-space: nowrap;"> 研究目的： </span>
								<span v-html="item.objective"></span>
							</div>
							<div style="margin-top: 8px; display: flex;color: rgba(0,0,0,0.65);">
								<span style="white-space: nowrap;"> 课题组： </span>
								<span> {{ item.reseachGroupName }} （KT2023040021）</span>
							</div>
						</div>

					</div>
				</div>
				<div v-if="itemData.length === 0"
					style="display: flex; align-items: center; justify-content: center;height: 100%;">
					<a-empty></a-empty>
				</div>
			</div>
		</a-spin>
		<div class="default-pagination">
			<a-pagination   @change="handlePageChange" v-model="pageNum" :pageSize="10" :total="total"
				show-less-items hideOnSinglePage/>
		</div>
	</div>
</template>

<script>
/* import {
	queryxxperiment,
	queryproject
} from '../../api/index.js' */
import {
	queryExperimentList,
	queryProjectList,
	queryExperimentInit
} from '../../api/others';
import moment from 'moment'
import searchBox from '../../components/searchBox.vue'
import SearchResearchGroupSelect from '../../components/searchResearchGroupSelect.vue';
export default {
	components: {
		searchBox,
		SearchResearchGroupSelect
	},
	watch: {

	},
	created() {
		let data = {
			matchRange: this.slectRange,
			experimentalStatus: undefined,
			createStartTime: undefined,
			createEndTime: undefined,
			updateStartTime: undefined,
			updateEndTime: undefined,
			createName: undefined,
			serchName: this.$route.query.query ? this.$route.query.query : '',
			pageNum: this.pageNum,
			pageSize: this.pageSize
		}
		this.queryxxperiment(data);
		this.initProjectList();
	},
	data() {
		return {
			startTime: undefined,
			endTime: undefined,
			model: this.$route.query.type ? this.$route.query.type : '实验',
			upStartTime: undefined,
			createName: '',
			createTime: undefined,
			upEndTime: undefined,
			owner: '',
			experData: [],
			itemData: [],
			rangeColumn: [{
				name: '全部',
				key: 1
			},
			{
				name: '实验名称',
				key: 2
			},
			{
				name: '实验编号',
				key: 3
			},
			{
				name: '关键词',
				key: 4
			},
			],
			statusColumn: [{
				name: '全部',
				key: 4
			},
			{
				name: '编辑',
				key: 1
			},
			{
				name: '申请归档',
				key: 2
			},
			{
				name: '归档成功',
				key: 3
			},

			],
			itemRangeColumn: [{
				name: '全部',
				key: 1
			},
			{
				name: '项目名称',
				key: 2
			},
			{
				name: '研究目的',
				key: 3
			},
			{
				name: '项目编号',
				key: 4
			},
			],
			slectRange: 1,
			selectItemKey: 1,
			slectStatus: 4,
			searchName: '',
			pageNum: 1,
			total: 0,
			pageSize: 10,
			loading: false,

			projectId: undefined,
			projectList: [],
			isProjectListLoading: true,

			researchGoupId: undefined
		}
	},
	methods: {
		selectRange(val) {
			this.slectRange = val
		},
		selectItemRange(val) {
			this.selectItemKey = val
		},
		selectStatus(val) {
			this.slectStatus = val
		},
		disabledDate(current) {
			return current && current > moment(this.endTime)
		},
		disabledDate2(current) {
			return current && current < moment(this.startTime)
		},
		disabledDateUp(current) {
			return current && current > moment(this.upEndTime)
		},
		disabledDateUp2(current) {
			return current && current < moment(this.upStartTime)
		},
		submit(val) {
			this.searchName = val
			if (this.model == '实验') {
				let data = {
					matchRange: this.slectRange,
					experimentalStatus: this.slectStatus,
					createStartTime: this.startTime ? moment(this.startTime).format('YYYY-MM-DD') : '',
					createEndTime: this.startTime ? moment(this.endTime).format('YYYY-MM-DD') : '',
					updateStartTime: this.upStartTime ? moment(this.upStartTime).format('YYYY-MM-DD') : '',
					updateEndTime: this.upEndTime ? moment(this.upEndTime).format('YYYY-MM-DD') : '',
					createUserName: this.createName,
					serchName: this.searchName,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
					projectId: this.projectId
				}
				this.queryxxperiment(data)
			}
			if (this.model == '项目') {
				let data = {
					matchRange: this.selectItemKey,
					createTime: this.createTime ? moment(this.createTime).format('YYYY-MM-DD') : '',
					headName: this.owner,
					serchName: this.searchName,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
					reseachGroupId: this.researchGoupId
				}
				this.queryproject(data)
			}
		},
		select(val, serarchVal) {
			this.searchName = serarchVal;
			if (this.mode !== val) {
				this.pageNum = 1;
			}
			this.model = val
			if (this.model == '实验') {
				this.itemData = [];
				this.slectRange = 1;
				let data = {
					matchRange: this.slectRange,
					experimentalStatus: this.slectStatus,
					createStartTime: this.startTime,
					createEndTime: this.startTime,
					updateStartTime: this.upStartTime,
					updateEndTime: this.upEndTime,
					createUserName: this.createName,
					serchName: this.searchName,
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}
				this.queryxxperiment(data)
			}
			if (this.model == '项目') {
				this.selectItemKey = 1;
				this.experData = [];
				let data = {
					matchRange: this.selectItemKey,
					createTime: this.createTime,
					headName: this.owner,
					serchName: this.searchName,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
					reseachGroupId: this.reseachGroupId
				}
				this.queryproject(data)
			}
		},
		async queryxxperiment(data) {
			/* const res = await queryxxperiment(data)
			if (res.data.success) {
				this.experData = res.data.data
			} */
			this.loading = true;
			const res = await queryExperimentList(data);
			if (res.code === 200) {
				this.experData = res.data.data;
				this.total = res.data.count;
				if (this.searchName && this.searchName.trim()) {
					// 全部
					if (this.slectRange == 1) {
						this.experData.forEach(experiment => {
							experiment.title = this.handleTextHighLight(experiment.title, this.searchName.trim());
							experiment.code = this.handleTextHighLight(experiment.code, this.searchName.trim());
							experiment.keyWord = this.handleTextHighLight(experiment.keyWord, this.searchName.trim());
						})
					}
					// 标题高亮
					else if (this.slectRange == 2) {
						this.experData.forEach(experiment => {
							experiment.title = this.handleTextHighLight(experiment.title, this.searchName.trim());
						})
					}
					// 实验编号高亮
					else if (this.slectRange == 3) {
						this.experData.forEach(experiment => {
							experiment.code = this.handleTextHighLight(experiment.code, this.searchName.trim());
						})
					}
					// 实验关键词高亮
					else if (this.slectRange == 4) {
						this.experData.forEach(experiment => {
							experiment.keyWord = this.handleTextHighLight(experiment.keyWord, this.searchName.trim());
						})
					}
					this.experData = [...this.experData];
				}
			}
			this.loading = false;
			this.$refs.experimentRef && (this.$refs.experimentRef.scrollTop = 0);

		},
		async queryproject(data) {
			this.loading = true;
			const res = await queryProjectList(data)
			if (res.code === 200) {
				this.itemData = res.data.data;
				this.total = res.data.count;
				if (this.searchName && this.searchName.trim()) {
					console.log(this.selectItemKey)
					if (this.selectItemKey == 1) {
						this.itemData.forEach(item => {
							item.name = this.handleTextHighLight(item.name, this.searchName);
							item.objective = this.handleTextHighLight(item.objective, this.searchName);
							item.code = this.handleTextHighLight(item.code, this.searchName);
						})
					}
					else if (this.selectItemKey == 2) {
						this.itemData.forEach(item => {
							item.name = this.handleTextHighLight(item.name, this.searchName);
						})
					}
					else if (this.selectItemKey == 3) {
						this.itemData.forEach(item => {
							item.objective = this.handleTextHighLight(item.objective, this.searchName);
						})
					}
					else if (this.selectItemKey == 4) {
						this.itemData.forEach(item => {
							item.code = this.handleTextHighLight(item.code, this.searchName);
						})
					}
				}
				this.loading = false;
				this.$refs.projectRef && (this.$refs.projectRef.scrollTop = 0);

			}
		},
		handlePageChange(page, pagesize) {
			if (this.model == '实验') {
				let data = {
					matchRange: this.slectRange,
					experimentalStatus: this.slectStatus,
					createStartTime: this.startTime ? moment(this.startTime).format('YYYY-MM-DD') : '',
					createEndTime: this.startTime ? moment(this.endTime).format('YYYY-MM-DD') : '',
					updateStartTime: this.upStartTime ? moment(this.upStartTime).format('YYYY-MM-DD') : '',
					updateEndTime: this.upEndTime ? moment(this.upEndTime).format('YYYY-MM-DD') : '',
					createName: this.createName,
					serchName: this.searchName,
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}
				this.queryxxperiment(data)
			}
			if (this.model == '项目') {
				let data = {
					matchRange: this.selectItemKey,
					createTime: this.createTime ? moment(this.createTime).format('YYYY-MM-DD') : '',
					headName: this.owner,
					serchName: this.searchName,
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}
				this.queryproject(data)
			}
		},
		// 根据关键词高亮
		handleTextHighLight(text, keyWord) {
			if (!text) {
				return text;
			}
			const newText = text.replace(new RegExp(keyWord, 'g'), `<strong style="color: #FAAD14; "> ${keyWord}</strong>`);
			return `<span> ${newText} <span>`;
		},
		// 项目点击事件
		handleItemClick(item) {
			const href = this.$router.resolve({
				name: 'itemCenter',
				query: {
					projectId: item.id
				}
			})
			/* window.open(href.href, "_blank") */
			return href.href;
		},
		// 实验点击事件
		handleExperimentItemClick(item) {
			const mode = item.authorizationType === 1 ? 'preview' : 'edit';
			const href = this.$router.resolve({
				name: 'experiment',
				query: {
					id: item.experimentId,
					projectId: item.projectId,
					mode: mode
				}
			});
			return href.href
		},
		async initProjectList() {
			if (this.projectList.length > 0) {
				return;
			}
			this.isProjectListLoading = true;
			const res = await queryExperimentInit();
			if (res.success) {
				this.projectList = res.data;
			}
			this.isProjectListLoading = false;
		},
		filterOption(input, option) {
			return (
				option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			);
		},
		handleReseachGroupSelect(value) {
			this.researchGoupId = value;
			/* this.submit(this.searchName) */

		}
	}
}
</script>

<style lang="less" scoped>
.search {
	/* height: 120px; */
	background-color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 5px solid #F7F8FA;
	text-align: left;
}

.h {
	height: 200px !important;
}

.screen {
	min-width: 1200px;
	margin: 0 120px;
	height: 268px;
	background-color: white;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-size: 14px;
	border-radius: 4px;
}

.screen_list {
	margin-right: 40px;
	cursor: pointer;
}

.listActive {
	color: @srims-primary-color !important;
}

.msg {
	font-size: 14px;
	margin: 0 0px;
	padding: 0px 25px;
	transition: .3s all ease-in-out;
}

.msg:hover {
	background-color: rgba(1, 1, 1, 0.1);
}

.msg-content {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #E6E4E7;
	padding: 15px 0px;
}

.content {
	min-width: 1200px;
	margin: 0 120px;
	height: 320px;
	background-color: white;
	margin-top: 20px;
	min-height: 500px;
	overflow-y: auto;
	color: rgba(0, 0, 0, 0.85);
	margin-bottom: 20px;
}

.link:hover {
	text-decoration: underline;
	opacity: 0.6;
}
</style>

<style lang="less" scoped>
.default-pagination {
	text-align: center;
	margin-bottom: 24px;
}
</style>