<template>
	<!-- <div
		style="width: 482px;height: 40px;border: 1px solid rgba(0, 0, 0, 0.15);display: flex;align-items: center;position: relative;">
		<a-dropdown>
			<a class="ant-dropdown-link" @click="e => e.preventDefault()">
				{{ model }}
				<a-icon type="down" />
			</a>
			<a-menu slot="overlay" style="margin-top: 4px;margin-left: -8px;">
				<a-menu-item @click="select('实验')" v-if="model != '实验'">
					<a href="javascript:;">实验</a>
				</a-menu-item>
				<a-menu-item @click="select('项目')" v-if="model != '项目'">
					<a href="javascript:;">项目</a>
				</a-menu-item>
			</a-menu>
		</a-dropdown>
		<div style="width: 1px;background-color: #EBEBEB;height: 30px;margin-left: 15px;">
		</div>
		<a-input v-model="value" style="width: 83%;border-color: transparent;margin-left: 5px;" allowClear @pressEnter="submit"></a-input>
		<div class="btn" @click="submit">
			搜索
		</div>
	</div> -->
	<div style="width: 500px; padding: 0 16px 32px 16px; background:#fff ;">
		<a-tabs v-model="model" @change="select">
			<a-tab-pane key="实验" tab="搜实验">
				
					<!-- <div style="width: 1px;background-color: #EBEBEB;height: 30px;margin-left: 15px;">
					</div> -->
					<a-input-search size="large" enterButton v-model="value" style="width: 95%;margin-left: 5px;" allowClear
						@search="submit"></a-input-search>
					<!-- <div class="btn" @click="submit">
						搜索
					</div> -->
				
			</a-tab-pane>
			<a-tab-pane key="项目" tab="搜项目">
				<!-- <div style="width: 1px;background-color: #EBEBEB;height: 30px;margin-left: 15px;">
				</div> -->
				<a-input-search v-model="value" size="large" enterButton style="width: 95%;margin-left: 5px;" allowClear
						@search="submit"></a-input-search>
				
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
export default {
	data() {
		return {
			value: this.$route.query.query ? this.$route.query.query : '',
			model: this.$route.query.type ? this.$route.query.type : '实验'
		}
	},
	methods: {
		submit() {
			this.changeRoute();
			this.$emit('submit', this.value)
		},
		select(val) {
			//this.model = val
			this.changeRoute();
			this.$emit('select', this.model, this.value)
		},
		changeRoute() {
			if (this.value != this.$route.query.query || this.model != this.$route.query.type) {
				this.$router.replace({
					name: 'search',
					query: {
						type: this.model,
						query: this.value
					}
				})
			}
		}
	}
}
</script>

<style scoped>
.ant-dropdown-link {
	color: rgba(0, 0, 0, 0.85);
	margin-left: 15px;
	font-size: 14px;
}

.btn {
	width: 70px;
	/* height: 40px; */
	/* position: absolute; */
	margin-left: 5px;
	background-color: #3053EB;
	/* right: -70px; */
	cursor: pointer;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 8px;
	padding-left: 5px;
	font-size: 16px;
}
</style>
<style lang="less" scoped>
/deep/ .ant-input {
	//border: none;
}
/* /deep/ .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

/deep/ .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

/deep/ .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

/deep/ .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
 */
 /deep/ .ant-tabs-nav .ant-tabs-tab-active {
            color: @srims-primary-color;
        }

        /deep/ .ant-tabs-ink-bar {
            background-color: @srims-primary-color;
        }
</style>